/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import { Link } from "gatsby";
import LayoutHome from "../layout/LayoutHome";
import dtdLogo from "../../static/imgs/double-the-donation-logo.png";

const AtlantaEmpMatching = () => {
  return (
    <LayoutHome
      seoTitle="Employer Matching - Donations"
      contentHeader={<h3>Employer Matching</h3>}
      contentHeaderStyle="contentHeaderText"
    >
      <Box sx={{ margin: "20px 0" }}>
        <h3 sx={{ marginBottom: "20px", fontWeight: "bold", fontSize: "18px" }}>
          Support Heartfulness through Corporate Employer Matching Programs.
        </h3>
        <p>
          The Heartfulness Institute is 100% volunteer managed and operated. We
          are seeking corporate philanthropic donations to support this effort
          for human well-being. Find out below if your company has a matching
          gift program. Contact us if you have any questions:{" "}
          <a href="mailto:us.donations@heartfulness.org">
            us.donations@heartfulness.org
          </a>
        </p>

        <Link to="/corporate-gift-matching">
          List of companies where our organizations are already approved for
          Corporate Gift Matching.
        </Link>
      </Box>
      <Box
        sx={{
          boxShadow: "0 2px 4px 0 rgb(170 170 170 / 50%)",
          border: "solid 1px rgba(194,198,206,0.5)",
          padding: "30px",
          textAlign: "center",
        }}
      >
        <p
          sx={{
            p: 5,
            fontSize: "20px",
            fontWeight: "bold",
            lineHeight: "40px",
          }}
        >
          There is an issue preventing{" "}
          <a sx={{ color: "#1da1f2" }} href="https://doublethedonation.com/">
            Double the Donation`s tool
          </a>{" "}
          from displaying the matching gift forms, guidelines, and instructions
          that your donors need to submit their match requests.{" "}
          <a
            sx={{ color: "#1da1f2" }}
            href="https://support.doublethedonation.com/portal/en/kb/articles/plugin-error-messaging-issue-preventing-tool-from-displaying-matching-gift-forms"
          >
            Learn more about this issue
          </a>{" "}
          and then contact{" "}
          <a
            sx={{ color: "#1da1f2" }}
            href="https://doublethedonation.com/contact-us/"
          >
            Double the Donation`s support team
          </a>{" "}
          to resolve this issue.
        </p>
        <small>All information provided by</small>
        <br />
        <a
          sx={{ color: "#1da1f2", fontWeight: "bold" }}
          href="https://doublethedonation.com/tips/corporate-matching-gift-programs/"
        >
          Double the Donation
        </a>
        <br />
        <a
          target="_blank"
          rel="noreferrer"
          href="https://doublethedonation.com/"
        >
          <img src={dtdLogo} alt="logo" width="26px" height="26px" />
        </a>
      </Box>
      <Box>
        <p>Corporate Matching Programs – Common Questions:</p>
        <h2 sx={{ fontSize: "18px", fontWeight: "bold", mb: 2 }}>
          What are employee matching gift programs?
        </h2>
        <p>
          Employee matching gift programs are corporate giving programs in which
          the company matches donations made by employees to eligible nonprofit
          organizations. It’s an easy way to double your contribution.
        </p>
        <h2 sx={{ fontSize: "18px", fontWeight: "bold", mb: 2 }}>
          What are volunteer grant programs?
        </h2>
        <p>
          Volunteer grant programs are corporate giving programs in which
          companies provide monetary donations to organizations where employees
          volunteer regularly.
        </p>
        <p>
          If you volunteer with us, it’s an easy way to provide us with
          additional financial support.
        </p>
        <h2 sx={{ fontSize: "18px", fontWeight: "bold", mb: 2 }}>
          How do I request a matching gift or volunteer grant?
        </h2>
        <p>
          Requesting a matching gift or volunteer grant is normally a five
          minute process which must be initiated by the donor / volunteer. You
          can do this by filling out and submitting a paper match form provided
          by your employer or through an electronic submission process.
        </p>
        <h2 sx={{ fontSize: "18px", fontWeight: "bold", mb: 2 }}>
          How is this information obtained?
        </h2>
        <p>
          We partner with a company called{" "}
          <a
            sx={{ color: "#1da1f2" }}
            target="_blank"
            rel="noreferrer"
            href="https://doublethedonation.com/"
          >
            Double the Donation
          </a>
          . If you see anything that should be changed, please email Double the
          Donation’s team at{" "}
          <a href="mailto:data@doublethedonation.com" sx={{ color: "#1da1f2" }}>
            data@doublethedonation.com
          </a>
          .
        </p>
        <h2 sx={{ fontSize: "18px", fontWeight: "bold", mb: 2 }}>
          What if I still have questions?
        </h2>
        <p>
          For questions regarding your company’s programs, please contact your
          employer’s HR or community giving department. Much of the necessary
          information is also available on your company intranet.
        </p>
        <p>
          For questions regarding submitting a matching gift or volunteer grant
          request to us, please contact{" "}
          <a
            href="mailto:us.donations@heartfulness.org"
            sx={{ color: "#1da1f2" }}
          >
            us.donations@heartfulness.org
          </a>
          .
        </p>
      </Box>
    </LayoutHome>
  );
};

export default AtlantaEmpMatching;
